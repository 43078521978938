@import "../../assets/styles/mainVariables";
@import "../../assets/styles/fonts";

.news-wrapper{
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .news-block{
    width: 1120px;
    padding: 110px 0 ;
    margin-top: 70px;
    &-bg {
      //width: 58%;
      width: auto;
      height: calc(460px + $headerHeight);
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: -1;
    }

    .info-sub-title {
      color: $brandColor;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      text-align: center;
      margin: 0 auto 32px;
    }

    .info-title {
      width: 80%;
      color: #231F20;
      text-align: center;
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      line-height: 60px;
      letter-spacing: -0.48px;
      margin: 0 auto 24px;
    }

    .info-description {
      width: 80%;
      color: #696773;
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      margin: 0 auto 70px;
    }
    .news-slider{
      display: flex;
      flex-wrap: wrap;
      gap: 32px;
      .news-card{
        width: calc(100% / 3 - 24px);
      }
    }
  }

  .news_pagination{
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    .pagination{
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      margin-bottom: 20px;
      &.hide-prev{
        .ant-pagination-prev{
          display: none;
        }
      }
      &.hide-prev{
        .ant-pagination-next{
          display: none;
        }
      }
      .prev_btn{
        height: 40px;
        position: absolute;
        left: 0;
        display: flex;
        align-items: center;
        span{
          font-size: 14px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0;
          text-align: left;
          color: #475467;
        }
        svg{
          margin-right: 12px;
        }
      }
      .next_btn{
        height: 40px;
        position: absolute;
        right: 0;
        display: flex;
        align-items: center;
        span{
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: 0;
          text-align: left;
          color: #667085;
        }
        svg{
          margin-left: 12px;
        }
      }
      .ant-pagination-item{
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: unset;
        a{
          color: #475467;
        }
      }
      .ant-pagination-item-active{
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        background:#F9FAFB;
        a{
          color: #1D2939;
        }
      }
    }
  }

  @media screen and (max-width: 1250px){
    .newsBlock{
      width: 1020px;
    }
    .news-block{
      width: 1020px;
    }
  }

  @media screen and (max-width: 1050px){
    .news-block{
      width: 100%;
      padding: 64px $mainPadding 140px $mainPadding;
      &-bg {
        width: 100%;
        height: calc(360px + $headerHeight);
      }

      .info-sub-title {
        width: 100%;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 20px;
      }

      .info-title {
        width: 100%;
        color: rgb(21, 19, 25);
        font-size: 36px;
        font-weight: 700;
        line-height: 46px;
        margin-bottom: 24px;
      }

      .info-description {
        width: 100%;
        font-size: 20px;
        line-height: 30px;
        margin-top: 20px;
      }
      .news-slider{
        display: flex;
        flex-wrap: wrap;
        gap: 32px;
        .news-card{
          width: calc(100% / 2 - 16px);
        }
      }
    }
  }
}
@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/fonts";

.subscribers-wrapper {
  padding: 100px $mainPadding;
  position: relative;
  background: linear-gradient(to bottom, white 60%, $greyBgColor);

  &-ellipse-icon {
    position: absolute;
    right: 0;
    top: 20px;
    transform: translateX(50%);
    height: 378px;
  }

  &-line {
    position: absolute;
    left: calc($mainPadding - 32px);
    top: 20px;
    //transform: translateX(50%);
    height: 100%;
    width: 2px;
  }

  &-title {
    width: 80%;
    margin: auto;
    color: $darkTextColor;
    font-size: 36px;
    font-weight: 700;
    line-height: 48px;
    margin-bottom: 32px;
    text-align: center;
  }

  &-description {
    width: 80%;
    margin: auto;
    color: $textColor;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    margin-bottom: 40px;
    text-align: center;
  }

  &-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 92px;

    .list-item {
      width: 48%;
      margin-bottom: 32px;

      &-name {
        color: $brandColor;
        font-size: 24px;
        font-weight: 600;
        line-height: 30px;
        margin-bottom: 10px;
      }

      &-description {
        color: $textColor;
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
      }
    }
  }

  @media all and (max-width: 800px) {
    background: white;

    &-title {
      width: 100%;
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 26px;
      text-align: left;
    }

    &-description {
      width: 100%;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 44px;
      text-align: left;
    }

    &-list {
      margin-top: 72px;

      .list-item {
        width: 100%;

        &-name {
          font-size: 16px;
          line-height: 24px;
        }

        &-description {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }
}

@import "../../assets/styles/mainVariables";
@import "../../assets/styles/fonts";


.contacts-page {
  padding-top: $headerHeight;

  &-header {
    display: none;
  }

  &-bg {
    //width: 58%;
    width: auto;
    height: calc(460px + $headerHeight);
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
  }

  &-title {
    color: $darkTextColor;
    font-size: 40px;
    font-weight: 700;
    line-height: 52px;
    letter-spacing: -1%;
    margin-bottom: 20px;
  }

  &-description {
    color: $textColor;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    margin-bottom: 52px;
  }

  .top-part {
    width: 100%;
    display: flex;

    .image-wrapper {
      width: 50%;
      background-color: $greyBgColor;
      position: relative;

      .first {
        width: 280px;
        height: 100%;
        object-fit: cover;
        object-position: left;
      }

      .second {
        width: auto;
        height: 732px;
        object-fit: cover;
        position: absolute;
        top: 50%;
        left: 200px;
        z-index: 1;
        transform: translateY(-50%);
      }
    }

    .form-wrapper {
      width: 50%;
      padding: 60px $mainPadding 60px 80px;

      .row {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .custom-input-group {
          width: calc(50% - 8px);
        }
      }

      .sent-email {
        width: 100%;
        height: 56px;
        border-radius: 8px;
        background: rgb(127, 86, 217);
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color: white;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        text-align: left;

        svg {
          margin-left: 6px;
        }
      }

      &-services {
        p {
          color: $darkTextColor;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          text-align: left;
          margin-bottom: 16px;
        }

        .type-item {
          display: flex;
          align-items: start;
          margin-bottom: 16px;
          color: $textColor;
          font-size: 16px;
          font-weight: 500;
          line-height: 16px;
          text-align: left;

          .custom-checkbox {
            box-sizing: border-box;
            border: 1px solid rgb(208, 213, 221);
            border-radius: 6px;
            background-color: white;
            width: 20px;
            height: 20px;
            position: relative;
            margin-right: 12px;
            cursor: pointer;

            &.checkbox {
              background-color: $brandColor;

              &:after {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                content: "\2713";
                color: white;
                font-weight: 600;
                font-size: 12px;
              }
            }
          }

          &:last-child {
            margin-bottom: 32px;
          }
        }
      }
    }
  }

  .bottom-part {
    padding: 142px $mainPadding;
    display: flex;
    align-items: center;
    gap: 32px;

    .contact-info {
      width: calc(100% / 3);
      background-color: $greyBgColor;
      padding: 32px;
      border-radius: 8px;


      .info-icon {
        width: 48px;
        height: 48px;
        flex-grow: 0;
        border-radius: 8px;
        background: $brandColor;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 46px;
      }

      .info-title {
        color: $darkTextColor;
        font-size: 20px;
        font-weight: 700;
        line-height: 30px;
        text-align: left;
        margin-bottom: 8px;
      }

      .info-desc {
        color: $textColor;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        margin-bottom: 24px;
        height: 48px;
        overflow: hidden;
      }

      .info-val {
        color: $brandColor;
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  @media screen and (max-width: 1300px) {
    .top-part {

      .image-wrapper {

        .first {
          width: 260px;
          height: 100%;
        }

        .second {
          width: auto;
          height: 632px;
          left: 180px;
        }
      }

    }
  }

  @media screen and (max-width: 1050px) {
    &-header {
      display: block;
      text-align: center;
      padding: 64px 16px;
    }
    &-bg {
      width: 58%;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: -1;
    }

    &-title {
      font-size: 32px;
      font-weight: 700;
      line-height: 44px;
      margin-bottom: 24px;
    }

    &-description {
      font-size: 18px;
      font-weight: 500;
      line-height: 28px;
      margin-bottom: 32px;
    }

    .top-part {
      flex-direction: column;

      .image-wrapper {
        width: 100%;
        height: 327px;
        overflow: hidden;

        .first {
          width: 281px;
          height: 408px;
          object-fit: contain;
          transform: translate(0, -34px);
        }

        .second {
          width: 159px;
          height: 275px;
          left: 200px;
          object-fit: cover;
        }
      }

      .form-wrapper {
        width: 100%;
        padding: 52px $mainPadding;

        .contacts-page-title {
          display: none;
        }

        .contacts-page-description {
          display: none;
        }

        @media screen and (max-width: 500px){
          .row{
            flex-direction: column;
            .custom-input-group{
              width: 100%;
            }
          }
        }
      }
    }
    .bottom-part {
      flex-direction: column;
      padding: 64px $mainPadding;
      background-color: #F7F7F7;

      .contact-info {
        width: 100%;
      }
    }
  }
}

@import "../../../../assets/styles/fonts";
@import "../../../../assets/styles/mainVariables";

.ant-modal-mask{
  background-color: rgba(0, 0, 0, 0.7) !important;
}
.newsModal{
  width: 620px!important;
  z-index: 1000;
  min-height: 500px!important;
  .ant-modal-content{
    border-radius: 12px;
    position: relative;
    min-height: 500px!important;
    .ant-modal-close{
      width: 40px;
      height: 40px;
      position: absolute;
      right: -40px;
      top: -18px;
      z-index: 999;
      .ant-modal-close-x{
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: color 0.5s;
        svg{
          path{
            stroke: white;
          }
        }
      }
      &:hover{
        .ant-modal-close-x{
          //background-color: rgba(255, 255, 255, 0.4) !important;
          cursor: pointer;
          svg{
            path{
              stroke: rgba(255, 255, 255, 0.7);
            }
          }
        }
      }
    }
    @media screen and (max-width: 1050px){
      .ant-modal-close{
        right: 2px;
        left: unset;
        top: -6px;
        .ant-modal-close-x{
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: background-color 0.5s;
          svg{
            path{
              stroke: black!important;
            }
          }
        }
      }
    }
  }
}
.news-modal-wrapper{
  width: 100%;
  border-radius: 12px;
  .slick-slider{
    width: 100%;
    //height: 286px;
    .slick-list{
      width: 100%;
      height: 100%;
      .news-block-img{
        aspect-ratio: 570 / 360;
        position: relative;
        border-radius: 14px;
        overflow: hidden;
        img{
          border-radius: 14px;
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
  .news-block-info{
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 32px;
    .news-date{
      color: $brandColor;
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
    }
    .news-modal-link{
      color: $brandColor;
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
    }
    .news-block-title{
      color: #231F20;
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px;
      margin-top: 6px;
    }
    .news-block-description{
      color: #696773;
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      margin-top: 8px;
      ul{
        li{
          list-style: disc;
          width: fit-content!important;
        }
      }
      p{
        color: #696773;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
        margin-top: 8px;
        span{
          color: #696773!important;
          text-align: center!important;
          font-size: 20px!important;
          font-style: normal!important;
          font-weight: 400!important;
          line-height: 30px!important;
          margin-top: 8px!important;
        }
      }
    }
    .news-modal-pdf{
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0;
      text-align: left;
      text-decoration: underline;
      color: #696773;
      cursor: pointer;
    }
  }
}
@media screen and (max-width: 1050px){
  .newsModal{
    width: 100%;
  }
  .news-modal-wrapper{
    .news-block-img{
      //padding-top: 78.7%;
    }
  }
}
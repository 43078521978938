@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/fonts";

.map-wrapper {
  position: relative;

  .map-img {
    width: 64%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-top: 65%;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: contain;
      object-position: left;
    }
  }

  &-right {
    padding-right: $mainPadding;
    width: 50%;
    position: absolute;
    top: 50%;
    right: 0;
    z-index: 1;
    transform: translateY(-50%);
  }

  &-svg {
    margin-bottom: 20px;
  }

  &-title {
    color: $darkTextColor;
    font-size: 36px;
    font-weight: 700;
    line-height: 48px;
    margin-bottom: 20px;
  }

  &-description {
    color: $textColor;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    margin-bottom: 20px;
  }

  &-more {
    cursor: pointer;
    margin-top: 20px;
    width: 198px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $textColor;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    background-color: transparent;
    border: 1px solid $textColor;
    border-radius: 60px;
    transition: all 0.2s;
    margin-bottom: 120px;

    &:hover{
      background-color: $darkTextColor;
      color: white;
      border: 1px solid $darkTextColor;
      svg{
        path{
          stroke: $darkTextColor;
        }
      }
    }
  }

  @media screen and (max-width: 1050px) {
    display: flex;
    flex-direction: column-reverse;
    padding: 52px 0;

    .map-img {
      width: 100%;
      padding-top: 60%;
    }

    &-right {
      padding: 0 $mainPadding 16px;
      position: unset;
      width: 100%;
      transform: unset;
    }

    &-svg {
      display: none;
    }
  }

  @media screen and (max-width: 600px) {
    display: flex;
    flex-direction: column-reverse;
    padding: 52px 0;
    border-bottom: 1px solid rgb(232, 230, 237);

    &-right {
      text-align: center;
      position: relative;
    }

    &-title {
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 24px;
    }

    &-description {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      margin-bottom: 24px;
    }

    &-more{
      width: 100%;
      margin-bottom: 16px;
    }

    &-svg {
      display: block;
      position: absolute;
      left: 50%;
      bottom: -46px;
      transform: translate(-50%, 100%);
    }
  }
}

@import "../../assets/styles/fonts";
@import "../../assets/styles/mainVariables";

.service-wrapper {
  padding-top: $headerHeight;
  position: relative;

  &-bg {
    //width: 58%;
    width: auto;
    height: calc(460px + $headerHeight);
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
  }

  .top-part {
    padding: 102px $mainPadding;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .service-sub-title {
      color: $brandColor;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      margin-bottom: 32px;
    }

    .service-title {
      width: 80%;
      color: #231F20;
      text-align: center;
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      line-height: 60px;
      letter-spacing: -0.48px;
      margin-bottom: 24px;
    }

    .service-description {
      width: 80%;
      color: #696773;
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      margin-bottom: 102px;
    }
  }

  .block-first {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 80px;

    .option-item {
      width: calc(100% / 6 - 20px);
      display: flex;
      flex-direction: column;
      justify-content: center;

      &-img {
        width: 48px;
        height: 48px;
        margin: 0 auto 20px;
        box-sizing: border-box;
        border: 1px solid rgb(234, 236, 240);
        border-radius: 10px;
        box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 24px;
          height: 24px;
        }
      }

      &-title {
        color: rgb(21, 19, 25);
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
        text-align: center;
        height: 60px;
      }
    }
  }

  .block-second {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left-part {
      width: 48%;

      .block-title {
        color: $darkTextColor;
        font-size: 36px;
        font-weight: 700;
        line-height: 48px;
        text-align: left;
        margin-bottom: 24px;
        max-height: 144px;
        overflow: hidden;
      }

      .block-description {
        color: $textColor;
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
      }

      .block-btn {
        width: max-content;
        height: 56px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 20px 40px 20px 40px;
        flex: none;
        order: 2;
        flex-grow: 0;
        margin: 24px 0;
        border-radius: 40px;
        border: 1px solid rgb(21, 19, 25);
        background: rgb(21, 19, 25);
        color: white;
        transition: all 0.2s;
        cursor: pointer;

        svg {
          transition: all 0.2s;
        }

        &:hover {
          background-color: transparent;
          color: $darkTextColor;
          border: 1px solid $darkTextColor;

          svg {
            path {
              stroke: $darkTextColor;
            }
          }
        }
      }
    }

    .right-part {
      width: 48%;
      min-height: 460px;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      .bg-wrapper {
        width: 401px;
        height: 232px;
        border-radius: 16px;
        background-color: $brandColor;
        position: absolute;
        left: 60px;
        top: 20px;
        z-index: 1;

        .bg {
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          bottom: 0;
        }
      }

      .img-wrapper {
        width: calc(100%);
        padding-top: 54%;
        position: relative;
        border-radius: 16px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          bottom: 0;
          object-fit: cover;
        }
      }

      .count-item-first {
        position: absolute;
        z-index: 2;
        left: 40px;
        top: 0;
        width: 401px;
        height: 232px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 32px 24px 32px 24px;
        box-sizing: border-box;
        border: 1px solid rgba(255, 255, 255, 0.3);
        border-radius: 16px;
        backdrop-filter: blur(24px);
        background: rgba(255, 255, 255, 0.3);
        overflow: hidden;
      }

      .count-item-second {
        position: absolute;
        bottom: -18px;
        right: 50%;
        transform: translateX(50%);
        z-index: 3;
        width: 205px;
        height: 204px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 32px 24px 32px 24px;
        box-sizing: border-box;
        border: 1px solid rgba(255, 255, 255, 0.3);
        border-radius: 16px 16px 0 0;
        backdrop-filter: blur(24px);
        background: rgba(255, 255, 255, 0.3);
        overflow: hidden;

        &:after {
          content: " ";
          position: absolute;
          bottom: -14px;
          left: 0;
          width: 100%;
          height: 40px;
          filter: blur(24px);
          background: rgba(255, 255, 255, 1);
          //background: red;
          z-index: 1;
        }
      }

      .item-title {
        color: white;
        font-size: 60px;
        font-weight: 700;
        line-height: 72px;
        letter-spacing: -2%;
        text-align: center;
      }

      .item-description {
        color: white;
        font-size: 18px;
        font-weight: 600;
        line-height: 28px;
        text-align: center;
        position: relative;
      }
    }
  }

  .blocks-third {
    padding: 0 $mainPadding;
    text-align: center;
    margin: 150px 0;

    &-title {
      width: 80%;
      color: $darkTextColor;
      font-size: 36px;
      font-weight: 700;
      line-height: 48px;
      margin: 0 auto 20px;
    }

    &-description {
      width: 80%;
      color: $textColor;
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      margin: 0 auto 68px;
      max-height: 60px;
      overflow: hidden;
    }

    .options-list {
      display: flex;
      justify-content: space-between;
      //align-items: center;

      .option-item {
        margin-bottom: 64px;
        width: 22%;

        &-img {
          width: 58px;
          height: 58px;
          border-radius: 12px;
          box-shadow: 0 8px 12px 0 rgba(135, 145, 233, 0.3);
          background: $brandColor;
          margin: auto;
          margin-bottom: 12px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 32px;
            height: 32px;
          }
        }

        &-title {
          color: #151319FF;
          font-size: 20px;
          font-weight: 600;
          line-height: 30px;
          margin-bottom: 8px;
        }

        &-description {
          color: $textColor;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
        }
      }
    }
  }

  .block-fourth {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left-part {
      width: 48%;
      min-height: 460px;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      .bg-wrapper {
        width: 60%;
        border-radius: 16px;
        background-color: $brandColor;
        position: absolute;
        z-index: 0;
        padding-top: 40%;
        top: 0;
        right: 0;

        .bg {
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          bottom: 0;
        }
      }

      .img-wrapper {
        width: calc(100% - 68px);
        padding-top: 60%;
        position: relative;
        border-radius: 16px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          bottom: 0;
          object-fit: cover;
        }
      }

      .count-item-first {
        position: absolute;
        z-index: 1;
        right: 20px;
        bottom: 64px;
        width: 401px;
        height: 232px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 32px 24px 32px 24px;
        box-sizing: border-box;
        border: 1px solid rgba(255, 255, 255, 0.3);
        border-radius: 16px;
        backdrop-filter: blur(24px);
        background: rgba(255, 255, 255, 0.3);
        overflow: hidden;
      }

      .count-item-second {
        position: absolute;
        top: -18px;
        right: 48px;
        z-index: 2;
        width: 205px;
        height: 204px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 32px 24px 32px 24px;
        box-sizing: border-box;
        border: 1px solid rgba(255, 255, 255, 0.3);
        border-radius: 0 0 16px 16px;
        backdrop-filter: blur(24px);
        background: rgba(255, 255, 255, 0.3);
      }

      .item-title {
        color: white;
        font-size: 60px;
        font-weight: 700;
        line-height: 72px;
        letter-spacing: -2%;
        text-align: center;
      }

      .item-description {
        color: white;
        font-size: 18px;
        font-weight: 600;
        line-height: 28px;
        text-align: center;
      }
    }

    .right-part {
      width: 48%;

      .block-title {
        color: $darkTextColor;
        font-size: 36px;
        font-weight: 700;
        line-height: 48px;
        text-align: left;
        margin-bottom: 24px;
        max-height: 144px;
        overflow: hidden;
      }

      .block-description {
        color: $textColor;
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        max-height: 210px;
        overflow: hidden;
      }

      .block-btn {
        width: max-content;
        height: 56px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 20px 40px 20px 40px;
        flex: none;
        order: 2;
        flex-grow: 0;
        margin: 24px 0;
        border-radius: 40px;
        background: rgb(21, 19, 25);
        border: 1px solid rgb(21, 19, 25);
        color: white;
        transition: all 0.2s;
        cursor: pointer;

        svg {
          transition: all 0.2s;
        }

        &:hover {
          background-color: transparent;
          color: $darkTextColor;
          border: 1px solid $darkTextColor;

          svg {
            path {
              stroke: $darkTextColor;
            }
          }
        }
      }
    }
  }

  .bottom-part {
    padding: 98px 0;

    &-text {
      width: 70%;
      color: $textColor;
      font-size: 20px;
      font-weight: 500;
      line-height: 30px;
      text-align: center;
      margin: auto;
      margin-bottom: 50px;
    }

    .partners-list-wrapper {
      position: relative;
      width: 100% !important;

      .slick-slider {
        width: 100% !important;

        .slick-list {
          width: 100% !important;
          overflow: hidden;

          .slick-track {
            width: 100% !important;
            padding-top: 5px;
            display: flex;
            @media screen and (max-width: 400px) {
              margin-right: 90px;
            }
          }
        }
      }

      .slick-slide .slick-active {
        width: 300px;
      }

      .partner-item {
        border: 1px solid $borderColor;
        //width: 90%;
        //height: 100%;
        //position: relative;
        width: 160px;
        height: 160px;
        margin-right: 20px;

        .partner-content {
          width: 100%;
          height: 100%;
          padding-top: 100%;
          position: relative;

          img {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            object-fit: contain;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1050px) {
    overflow: hidden;

    &-bg {
      width: 100%;
      height: calc(360px + $headerHeight);
    }
    .top-part {
      padding: 64px 0;

      .service-sub-title {
        text-align: center;
        width: 100%;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 20px;
        padding: 0 $mainPadding;
      }

      .service-title {
        width: 100%;
        color: rgb(21, 19, 25);
        font-size: 36px;
        font-weight: 700;
        line-height: 46px;
        margin-bottom: 24px;
        padding: 0 $mainPadding;
      }

      .service-description {
        width: 100%;
        font-size: 18px;
        font-weight: 500;
        line-height: 28px;
        margin-bottom: 64px;
        padding: 0 $mainPadding;
      }
    }

    .block-first {
      width: 100%;
      flex-wrap: wrap;
      margin-bottom: 52px;
      background-color: #F8F8F8;
      padding: 35px $mainPadding;

      .option-item {
        width: calc(100% / 3 - 20px);
        margin-bottom: 24px;
      }
    }

    .block-second {
      flex-direction: column;
      padding-left: $mainPadding;

      .left-part {
        width: 100%;
        padding-right: $mainPadding;

        .block-title {
          color: $darkTextColor;
          font-size: 26px;
          line-height: 38px;
          margin-bottom: 20px;
          max-height: unset;
        }

        .block-description {
          font-size: 16px;
          line-height: 24px;
        }

        .block-btn {
        }
      }

      .right-part {
        width: 100%;
        min-height: unset;
        justify-content: flex-start;
        margin-top: 24px;

        .bg-wrapper {
          width: 80%;
          height: 90%;
          border-radius: 16px 0 0 16px;
          right: calc(-1 * $mainPadding);
          left: unset;
          top: unset;
          bottom: -42px;
          z-index: 0;

          .bg {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            bottom: 0;
          }
        }

        .img-wrapper {
          width: calc(100% - 20px);
          padding-top: 54%;
          position: relative;
          border-radius: 16px;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            bottom: 0;
            object-fit: cover;
          }
        }

        .count-item-first {
          display: none;
        }

        .count-item-second {
          bottom: -68px;
          right: 54px;
          transform: unset;
          z-index: 3;
          width: 205px;
          height: calc(100% + 100px);
          border: 1px solid rgba(255, 255, 255, 0.3);
          background: rgba(255, 255, 255, 0.3);
          border-radius: 0;
        }
      }
    }

    .blocks-third {
      padding: 52px $mainPadding 64px;
      background: rgb(248, 248, 248);
      margin-bottom: 0;

      &-title {
        width: 80%;
        font-size: 20px;
        line-height: 30px;
      }

      &-description {
        width: 80%;
        font-size: 16px;
        line-height: 24px;
        margin: 0 auto 40px;
        max-height: unset;
      }

      .options-list {
        flex-wrap: wrap;

        .option-item {
          width: 48%;
        }
      }
    }

    .block-fourth {
      flex-direction: column-reverse;
      padding: 52px 0 45px 0;

      .left-part {
        width: 100%;
        min-height: unset;
        justify-content: flex-start;
        margin-top: 60px;
        padding-right: $mainPadding;

        .bg-wrapper {
          width: 220px;
          position: absolute;
          z-index: 0;
          padding-top: 40%;
          top: -43px;
          right: unset;
          left: calc(-1 * $mainPadding);
          border-radius: 16px 16px 0 0;

          .bg {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            bottom: 0;
          }
        }

        .img-wrapper {
          width: calc(100%);
        }

        .count-item-first {
          position: absolute;
          z-index: 1;
          right: unset;
          //left: calc(-1 * $mainPadding);
          bottom: unset;
          top: -43px;
          width: 220px;
          height: calc(100% + 80px);
          border-radius: 0 16px 16px 0;
        }

        .count-item-second {
          display: none;
        }
      }

      .right-part {
        width: 100%;
        padding: 0 $mainPadding;

        .block-title {
          font-size: 26px;
          font-weight: 700;
          line-height: 38px;
          max-height: unset;
          overflow: hidden;
        }

        .block-description {
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          max-height: unset;
          overflow: hidden;
        }

        .block-btn {
          width: max-content;
          height: 56px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 20px 40px 20px 40px;
          flex: none;
          order: 2;
          flex-grow: 0;
          margin: 24px 0;
          border-radius: 40px;
          background: rgb(21, 19, 25);
          color: white;
        }
      }
    }

    .bottom-part {
      padding: 32px 0;

      &-text {
        padding: 0 $mainPadding;
        width: 100%;
        text-align: left;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        margin-bottom: 26px;
      }
    }
  }

  @media screen and (max-width: 600px) {

    .block-first {
      width: 100%;
      flex-wrap: wrap;
      margin-bottom: 52px;
      background: rgb(248, 248, 248);
      padding: 35px 0;

      .option-item {
        width: 100%;
        padding: 0 64px;
      }
    }

    .block-second {
      .left-part {
        .block-btn {
          width: 100%;
        }
      }
    }

    .blocks-third {
      .options-list {
        .option-item {
          width: 90%;
          margin: auto;
          margin-bottom: 32px;
        }
      }
    }

    .block-fourth {

      .right-part {

        .block-btn {
          width: 100%;
        }
      }
    }
  }
}

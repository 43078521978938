@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/fonts";

.homeService-wrapper {
  padding: 90px 0 194px $mainPadding;
  //background: linear-gradient(to bottom, #fefefe, $greyBgColor);
  background-color: #f6f6f6;
  position: relative;
  overflow: hidden;

  .homeService_ellipse_icon {
    position: absolute;
    right: 0;
    top: 20px;
    transform: translateX(50%);
    height: 378px;
  }

  .homeService-sub-title {
    color: $brandColor;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 16px;
    text-align: center;
  }

  .homeService-title {
    width: 60%;
    margin: auto;
    color: $darkTextColor;
    font-size: 36px;
    font-weight: 700;
    line-height: 48px;
    margin-bottom: 16px;
    text-align: center;
  }

  .homeService-description {
    width: 60%;
    margin: auto;
    color: $textColor;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    text-align: center;
  }

  .homeService-blocks {
    width: 100%;
    margin: 100px 0 0;
    display: flex;

    .right-part, .left-part {
      width: 50%;
    }

    .right-part {
      padding: 96px 0 0 0;
      position: relative;

      img {
        position: absolute;
        width: 100%;
        max-height: 100%;
        right: 0;
        object-fit: contain;
        object-position: right;
        z-index: 2;
      }
    }

    .homeService-card {
      width: 100%;
      padding: 64px;
      border-radius: 20px;
      background: transparent;
      color: $textColor;
      transition: all 0.2s;
      display: block;

      .homeService-card-title {
        font-size: 28px;
        font-weight: 700;
        line-height: 38px;
        max-height: 76px;
        font-style: normal;
        overflow: hidden;
        margin-bottom: 16px;
      }

      .homeService-card-description {
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        font-style: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        margin-bottom: 24px;
      }

      .homeService-card-more {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        display: flex;
        align-items: center;
        font-style: normal;
        cursor: pointer;

        svg {
          width: 20px;
          height: 20px;
          margin-left: 4px;
          transition: all 0.2s;

          path {
            stroke: $textColor;
          }
        }

        &:hover {
          svg {
            margin-left: 6px;
            margin-bottom: 4px;
          }
        }
      }

      &:hover {
        background-color: $brandColor;
        color: white;

        .homeService-card-more {
          svg {
            path {
              stroke: white;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 900px) {
    padding: 80px $mainPadding;
    background-color: white;
    .homeService-title {
      width: 90%;
    }

    .homeService-description {
      width: 90%;
    }

    .homeService-blocks {
      flex-direction: column;

      .left-part {
        width: 100%;
      }

      .right-part {
        display: none;
      }

      .homeService-card {
        width: 100%;
        padding: 64px;
        border-radius: 20px;
        color: $textColor;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);
        background: rgb(253, 253, 253);

        .homeService-card-title {
          font-size: 20px;
          font-weight: 700;
          line-height: 30px;
          max-height: unset;
          font-style: normal;
          overflow: unset;
          margin-bottom: 16px;
        }

        .homeService-card-description {
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          max-height: unset;
          font-style: normal;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          display: block;
          height: unset;
          overflow: unset;
          margin-bottom: 24px;
        }

        .homeService-card-more{
          color: $brandColor;

          svg{
            path{
              stroke: $brandColor!important;
            }
          }
        }

        &:first-child {
          background-color: unset;
          color: unset;
          margin-bottom: 32px;

          .homeService-card-more {
            svg {
              path {
                stroke: $textColor;
              }
            }
          }
        }

        &:last-child {
          .homeService-card-title {
            max-height: unset;
          }

          .homeService-card-more {
            svg {
              path {
                stroke: $textColor;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 800px) {
    padding: 80px $mainPadding;

    .homeService_ellipse_icon {
      position: absolute;
      right: 0;
      top: 0;
      transform: translate(10%, -50%);
      height: 205px;
    }

    .homeService-sub-title {
      font-size: 14px;
      line-height: 20px;
      text-align: left;
    }

    .homeService-title {
      width: 100%;
      font-weight: 700;
      text-align: left;
      font-size: 26px;
      line-height: 38px;
    }

    .homeService-description {
      width: 100%;
      font-size: 16px;
      line-height: 24px;
      text-align: left;
    }

    .homeService-blocks {
      flex-direction: column;

      .left-part {
        width: 100%;
      }

      .right-part {
        display: none;
      }

      .homeService-card {
        padding: 32px;

        //&:first-child {
        //  background-color: $brandColor;
        //  color: white;
        //  margin-bottom: 32px;
        //}
      }
    }
  }
}

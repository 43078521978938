@import "../../assets/styles/fonts";
@import "../../assets/styles/mainVariables";

.about-wrapper {
  padding-top: $headerHeight;
  position: relative;

  &-bg {
    width: 58%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
  }

  .about-header {
    padding: 102px $mainPadding;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .about-sub-title {
      color: $brandColor;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      margin-bottom: 32px;
    }

    .about-title {
      width: 80%;
      color: #231F20;
      text-align: center;
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      line-height: 60px;
      letter-spacing: -0.48px;
      margin-bottom: 24px;
    }

    .about-description {
      width: 80%;
      color: #696773;
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
    }
  }

  .blocks-first {
    //padding: 0 $mainPadding;
    width: calc(100% - (2 * $mainPadding));
    position: relative;
    padding-top: 31.5%;
    margin: 0 auto 96px auto;

    &-img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
      object-position: right;
    }

    .inner-part {
      padding: 64px;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .overlay-black {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        backdrop-filter: blur(24px);
        background-color: black;
        opacity: 0.4;
      }
    }

    .option-item {
      width: 30%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      padding: 32px 24px 32px 24px;
      order: 0;
      flex-grow: 0;
      margin: 0 32px;
      color: white;
      position: relative;
      border: 1px solid rgba(255, 255, 255, 0.3);
      border-radius: 16px;
      //border: 1px solid rgba(255, 255, 255, 0.3);
      //background: rgba(255, 255, 255);
      overflow: hidden;
      box-sizing: border-box;
      backdrop-filter: blur(24px);
      background: rgba(255, 255, 255, 0.3);

      .overlay {
        //position: absolute;
        //width: 100%;
        //height: 100%;
        //top: 0;
        //left: 0;
        //background: rgba(255, 255, 255);
        //backdrop-filter: blur(24px);
        //opacity: 0.3;
        //z-index: 0;

        //border-radius: 16px;
        //border: 1px solid rgba(255, 255, 255, 0.3);
        //border: 1px solid rgba(255, 255, 255);
        //background-color: black;
      }

      &-title {
        color: white;
        font-size: 60px;
        font-weight: 700;
        line-height: 72px;
        letter-spacing: -2%;
        text-align: center;
      }

      &-description {
        color: white;
        font-size: 18px;
        font-weight: 600;
        line-height: 28px;
        text-align: center;
        height: 112px;
        overflow: hidden;
      }
    }
  }

  .about-partners {
    position: relative;
    width: 100% !important;
    margin-bottom: 120px;

    &-text {
      padding: 0 $mainPadding;
      color: rgb(68, 60, 82);
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      text-align: left;
      margin-bottom: 32px;
    }

    .slick-slider {
      width: 100% !important;

      .slick-list {
        width: 100% !important;
        overflow: hidden;

        .slick-track {
          width: 100% !important;
          padding-top: 5px;
          display: flex;
          @media screen and (max-width: 400px) {
            margin-right: 90px;
          }
        }
      }
    }

    .slick-slide .slick-active {
      width: 300px;
    }

    .partner-item {
      border: 1px solid $borderColor;
      width: 160px;
      height: 160px;
      margin-right: 32px;

      .partner-content {
        width: 100%;
        height: 100%;
        padding-top: 62.5%;
        position: relative;

        img {
          width: 100%;
          height: 100%;
          //height: 100%;
          //margin: auto;
          //transition: all 0.3s;
          padding: 0 10px;
          position: absolute;
          top: 0;
          left: 0;
          object-fit: contain;
        }
      }
    }
  }

  .blocks-second {
    padding: 0 $mainPadding;
    text-align: center;
    margin-bottom: 120px;

    &-sub-title {
      color: $brandColor;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      margin-bottom: 12px;
    }

    &-title {
      width: 80%;
      color: $darkTextColor;
      font-size: 36px;
      font-weight: 700;
      line-height: 48px;
      margin: 0 auto 20px;
    }

    &-description {
      width: 80%;
      color: $textColor;
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      margin: 0 auto 68px;
      max-height: 60px;
      overflow: hidden;
    }

    .options-list {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;

      .option-item {
        margin-bottom: 64px;
        width: 30%;

        &-img {
          position: static;
          width: 48px;
          height: 48px;
          flex: none;
          order: 0;
          flex-grow: 0;
          margin: 20px auto;
          box-sizing: border-box;
          border: 1px solid rgb(234, 236, 240);
          border-radius: 10px;
          box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 24px;
            height: 24px;
          }
        }

        &-title {
          color: #151319FF;
          font-size: 20px;
          font-weight: 600;
          line-height: 30px;
        }

        &-description {
          color: $textColor;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
        }
      }
    }
  }

  .blocks-third {
    padding: 0 $mainPadding;
    text-align: center;
    margin-bottom: 80px;

    &-sub-title {
      color: $brandColor;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      margin-bottom: 12px;
    }

    &-title {
      width: 80%;
      color: $darkTextColor;
      font-size: 36px;
      font-weight: 700;
      line-height: 48px;
      margin: 0 auto 20px;
    }

    &-description {
      width: 80%;
      color: $textColor;
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      margin: 0 auto 68px;
      max-height: 60px;
      overflow: hidden;
    }

    .options-list {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .option-item {
        margin-bottom: 64px;
        width: 22%;

        &-img {
          width: 58px;
          height: 58px;
          border-radius: 12px;
          box-shadow: 0 8px 12px 0 rgba(135, 145, 233, 0.3);
          background: $brandColor;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: auto;
          margin-bottom: 12px;

          img {
            width: 32px;
            height: 32px;
          }
        }

        &-title {
          color: #151319FF;
          font-size: 20px;
          font-weight: 600;
          line-height: 30px;
          margin-bottom: 8px;
        }

        &-description {
          color: $textColor;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
        }
      }
    }
  }

  @media screen and (max-width: 1050px) {
    .about-header {
      text-align: center;
      padding: 64px $mainPadding;

      .about-sub-title {
        width: 100%;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 20px;
      }

      .about-title {
        width: 100%;
        color: rgb(21, 19, 25);
        font-size: 36px;
        font-weight: 700;
        line-height: 46px;
        margin-bottom: 24px;
      }

      .about-description {
        width: 100%;
        font-size: 18px;
        font-weight: 500;
        line-height: 28px;
      }
    }

    .blocks-first {
      width: 100%;
      position: relative;
      padding-top: unset;
      margin: 0 auto 64px auto;

      &-img {
        object-position: 70%;
      }

      .inner-part {
        padding: 64px $mainPadding;
        position: unset;
        flex-direction: column;
      }

      .option-item {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 32px 24px 32px 24px;
        margin-bottom: 32px;

        &-title {
          font-size: 60px;
          font-weight: 700;
          line-height: 72px;
        }

        &-description {
          font-size: 18px;
          font-weight: 600;
          line-height: 28px;
          height: 82px;
        }
      }
    }

    .about-partners {
      margin-bottom: 60px;

      &-text {
        padding: 0 $mainPadding;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
      }

      .slick-slider {
        width: 100% !important;

        .slick-list {
          width: 100% !important;
          overflow: hidden;

          .slick-track {
            width: 100% !important;
            padding-top: 5px;
            display: flex;
            @media screen and (max-width: 400px) {
              margin-right: 90px;
            }
          }
        }
      }

      .partner-item {
        border: 1px solid $borderColor;
        //width: 90%;
        //height: 100%;
        //position: relative;
        width: 160px;
        height: 160px;
        margin-right: 20px;

        .partner-content {
          width: 100%;
          height: 100%;
          padding-top: 62.5%;
          position: relative;

          img {
            width: 100%;
            height: 100%;
            //height: 100%;
            //margin: auto;
            //transition: all 0.3s;
            padding: 0 10px;
            position: absolute;
            top: 0;
            left: 0;
            object-fit: contain;
          }
        }
      }
    }

    .blocks-second {
      padding: 64px $mainPadding;
      margin-bottom: 0;
      background: rgb(248, 248, 248);

      &-sub-title {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 12px;
      }

      &-title {
        width: 100%;
        color: rgb(21, 19, 25);
        font-size: 26px;
        font-weight: 600;
        line-height: 38px;
        margin: 0 auto 20px;
      }

      &-description {
        width: 100%;
        max-height: unset;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        margin: 0 auto 40px;
      }

      .options-list {
        .option-item {
          margin-bottom: 40px;
          width: 100%;

          &-img {
            width: 40px;
            height: 40px;
            margin-bottom: 16px;
          }

          &-title {
            font-size: 18px;
            line-height: 28px;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    .blocks-third {
      padding: 52px $mainPadding;
      text-align: center;
      margin-bottom: 0;

      &-sub-title {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 12px;
      }

      &-title {
        width: 100%;
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
        margin: 0 auto 24px;
      }

      &-description {
        width: 100%;
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        margin: 0 auto 40px;
        max-height: unset;
      }

      .options-list {
        flex-wrap: wrap;

        .option-item {
          width: 84%;
          margin: 0 auto 32px;
        }
      }
    }
  }

  @media all and (max-width: 700px) {
    .about-partners {
      .partner-item {
        width: 120px;
        height: 52px;
        margin-right: 12px;
        border: 0;

        .partner-content {
          padding-top: 0;
        }
      }
    }
  }

  @media all and (max-width: 400px) {
    .about-header {
      .about-title {
        word-break: break-word;
      }
    }

    .about-partners {
      .partner-item {
        width: 120px;
        height: 52px;
        margin-right: 12px;
        border: 0;

        .partner-content {
          padding-top: 0;
        }
      }
    }
  }
}

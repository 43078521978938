@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/fonts";

.home-partners-wrapper {
  width: 100%;
  padding: 30px $mainPadding 60px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .partners-list-wrapper {
    width: 100%;
    display: flex;
    gap: 120px;
    justify-content: center;
    padding: 144px 0;

    .slick-slider {
      width: 100% !important;

      .slick-list {
        width: 100% !important;
        overflow: hidden;

        .slick-track {
          padding-top: 5px;
          display: flex;
          @media screen and (max-width: 400px) {
            margin-right: 90px;
          }
        }
      }
    }

    .slick-slide .slick-active {
      width: 300px;
    }

    .partner-item {
      max-width: 256px;
      width: 30%;

      .partner-content {
        width: 100%;
        height: 100%;
        padding-top: 36.7%;
        position: relative;

        img {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          object-fit: cover;
        }
      }
    }
  }

  @media all and (max-width: 1200px) {
    .partners-list-wrapper {
      gap: 80px;
    }
    .partners-title {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0;
      text-align: center;
      margin: 0 0 20px 0;
    }
    .next-btn, .prev-btn {
      display: none;
    }
  }

  @media all and (max-width: 1050px) {
    border-bottom: 1px solid rgb(234, 236, 240);
    overflow: auto;
    padding: 0 $mainPadding;

    &::-webkit-scrollbar {
      width: 0;
    }

    .partners-list-wrapper {
      //min-width: 432px;
      padding: 56px 0;
      gap: 24px;

      .partner-item {
        width: calc(100% / 3);
      }
    }
  }
}

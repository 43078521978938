@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/fonts";


.home-package{
  padding: 100px $mainPadding;
  background-color: #f7f7f7;

  &-title {
    //width: 70%;
    color: $darkTextColor;
    font-size: 36px;
    font-weight: 700;
    line-height: 48px;
    margin-bottom: 20px;
    text-align: center;
  }

  &-description {
    color: $textColor;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    text-align: center;
    margin-bottom: 92px;
  }

  &-blocks{
    display: flex;
    justify-content: space-between;

    .package-card{
      width: calc(25% - 32px);
      text-align: center;

      &-icon{
        width: 56px;
        height: 56px;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $brandColor;
        margin: auto;
        margin-bottom: 12px;

        img{
          width: 32px;
          height: 32px;
        }
      }
      &-title{
        color: $darkTextColor;
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        margin-bottom: 6px;
      }
      &-description{
        color: #443C52;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        word-wrap: break-word
      }
    }
  }

  @media screen and (max-width: 1050px){

    &-blocks{
      flex-wrap: wrap;

      .package-card{
        width: calc(50% - 32px);
        margin-bottom: 32px;
      }
    }
  }

  @media screen and (max-width: 600px){
    background-color: white;
    padding: 52px $mainPadding;
    &-title {
      width: 100%;
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
    }

    &-description {
      width: 100%;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      max-height: unset;
    }

    &-blocks{
      flex-wrap: wrap;

      .package-card{
        width: 100%;

        &-description{
          width: 70%;
          margin: auto;
        }
      }
    }
  }
}

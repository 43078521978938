@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/fonts";

.home-contact {
  display: flex;
  justify-content: space-between;
  padding: 0 $mainPadding;
  position: relative;
  background: linear-gradient(to right, $greyBgColor calc(100% - $mainPadding - 256px), $brandColor calc($mainPadding + 256px));


  &-sub-title {
    color: $brandColor;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 32px;
  }

  &-title {
    width: 80%;
    color: $darkTextColor;
    font-size: 36px;
    font-weight: 700;
    line-height: 48px;
    margin-bottom: 16px;
  }

  &-description {
    width: 80%;
    color: $textColor;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 12px;
    max-height: 48px;
    overflow: hidden;
  }

  &-button {
    color: white;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    background-color: $brandColor;
    width: 214px;
    height: 48px;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: $darkTextColor;
      color: white;

      svg {
        path {
          stroke: $darkTextColor;
        }
      }
    }
  }

  &-link {
    display: inline-block;
    margin-bottom: 24px;
    border-bottom: 2px solid $textColor;
    padding-bottom: 4px;
    cursor: pointer;
    transition: all 0.2s;

    svg {
      margin-right: 4px;
    }

    span {
      display: flex;
      align-items: center;
      color: $textColor;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      word-wrap: break-word
    }

    &:hover {
      border-color: $brandColor;
      svg {
        path{
          stroke: $brandColor;
        }
      }

      span {
        color: $brandColor;
      }
    }
  }

  &-left {
    width: 50%;
    padding: 50px 0;
  }

  &-right {
    height: auto;
    width: calc($mainPadding + 450px);
    background-image: url("../../../assets/images/contact_web.webp");
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
    background-position: right;
  }

  @media screen and (max-width: 1050px) {
    flex-direction: column;
    background: linear-gradient(to bottom, $greyBgColor calc(100% - 162px), $brandColor 162px);

    &-left {
      width: 100%;
    }

    &-right {
      height: 340px;
      width: 100%;
      background-image: url("../../../assets/images/contact_mobile.webp");
      background-repeat: no-repeat;
      background-size: contain;
      position: relative;
      background-position: center;
    }
  }

  @media screen and (max-width: 600px) {
    &-sub-title {
      font-size: 14px;
      line-height: 20px;
    }

    &-title {
      width: 90%;
      font-size: 26px;
      line-height: 38px;
      text-align: left;
    }

    &-description {
      width: 90%;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 44px;
      text-align: left;
    }
    &-button {
      width: 100%;
    }
  }
}

@import "../../assets/styles/mainVariables";
@import "../../assets/styles/fonts";

.product-wrapper{
  padding: 0 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  overflow: hidden;

  //&.fixed{
  //  position: fixed;
  //}
  .product-header{
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 100px 0;
    .product-title{
      color: #231F20;
      text-align: center;
      font-family: NotoArmenian;
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      line-height: 60px;
      letter-spacing: -0.48px;
    }
    .product-description{
      color: #696773;
      text-align: center;
      font-family: NotoArmenian;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      margin-top: 20px;
    }
  }
  .product-block{
    width: 100%;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 32px;
    padding: 52px 0;
    position: relative;
    .shop-block{
      width: 64px;
      height: 64px;
      position: absolute;
      right: -80px;
      bottom: 20px;
      .shoppingBag-wrapper{
        width: 64px;
        height: 64px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color:$brandColor;
        cursor: pointer;
        position: relative;
        span{
          width: 24px;
          height: 24px;
          border-radius: 50%;
          background-color: #C21F48;
          border: 0.5px solid white;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #FFF;
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 18px;
          position: absolute;
          top: 0;
          right: -6px;
        }
      }
    }
  }
  .pagination{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin:40px 0 20px 0;
    padding-top: 20px;
    border-top: 1px solid #D0D5DD;
    &.hide-prev{
      .ant-pagination-prev{
        display: none;
      }
    }
    &.hide-prev{
      .ant-pagination-next{
        display: none;
      }
    }
    .prev_btn{
      height: 40px;
      position: absolute;
      left: 0;
      display: flex;
      align-items: center;
      span{
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0;
        text-align: left;
        color: #475467;
      }
      svg{
        margin-right: 12px;
      }
    }
    .next_btn{
      height: 40px;
      position: absolute;
      right: 0;
      display: flex;
      align-items: center;
      span{
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0;
        text-align: left;
        color: #667085;
      }
      svg{
        margin-left: 12px;
      }
    }
    .ant-pagination-item{
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: unset;
      a{
        color: #475467;
      }
    }
    .ant-pagination-item-active{
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      background:#F9FAFB;
      a{
        color: #1D2939;
      }
    }
  }
  .subscribe-wrapper{
    padding: 100px 0;
  }

  @media screen and (max-width: 1050px){
    padding: 0 $mainPadding;
    .product-header{
      width: 100%;
      padding: 52px 0;
      .product-title{
        color: #231F20;
        font-family: NotoArmenian;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 46px;
        letter-spacing: -0.36px;
        text-align: left;
      }
      .product-description{
        color: #696773;
        font-family: NotoArmenian;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
        text-align: left;
      }
    }
    .product-block{
      flex-wrap: unset;
      flex-direction: column;
      gap: unset;
      .shop-block{
        right: 10px;
        bottom: 0;
      }
      .productCard-wrapper{
        width: 100%;
      }
    }
    .subscribe-wrapper{
      border-top: unset;
      padding: 100px 0 0 0;
    }
    .pagination{
      border-top: unset;
    }
  }
}
@import "../../assets/styles/mainVariables";
@import "../../assets/styles/fonts";

.info-wrapper {
  padding: $headerHeight $mainPadding 0;
  margin-top: 70px;

  &-bg {
    //width: 58%;
    width: auto;
    height: calc(460px + $headerHeight);
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
  }

  .info-sub-title {
    color: $brandColor;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    margin: 0 auto 32px;
  }

  .info-title {
    width: 80%;
    color: #231F20;
    text-align: center;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: -0.48px;
    margin: 0 auto 24px;
  }

  .info-description {
    width: 80%;
    color: #696773;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    margin: 0 auto 70px;
  }

  .blocks-first {
    display: flex;
    align-items: center;
    min-height: 100vh;

    .options-list {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      gap: 32px;

      .option-item {
        width: calc(100% / 3 - (64px / 3));
        height: 225px;
        border-radius: 8px;
        box-shadow: 2px 2px 8px 2px rgba(199, 203, 222, 0.2);
        background: rgb(255, 255, 255);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0 70px;
        cursor: pointer;
        transition: 0.2s all;

        &-icon {
          width: 24px;
          height: 24px;
        }

        &-title {
          color: $textColor;
          font-size: 20px;
          font-weight: 600;
          line-height: 30px;
          text-align: center;
          max-height: 188px;
          overflow: hidden;
        }

        &:hover {
          background-color: $brandColor;

          .option-item-icon {
            path {
              stroke: white;
            }
          }

          .option-item-title {
            color: white;
          }
        }
      }
    }
  }

  .blocks-second {
    padding: 126px 0;
    display: flex;
    gap: 32px;

    .blocks-second-left,
    .blocks-second-right {
      box-sizing: border-box;
      border: 1px solid rgb(218, 218, 218);
      border-radius: 20px;
      background: linear-gradient(139.01deg, rgb(238, 238, 238) -12.119%, rgba(254, 254, 254, 0.24) 90.547%, rgba(255, 255, 255, 0) 98.567%);
    }

    .blocks-second-left {
      width: calc(100% - 352px);
      padding: 48px;

      .block-title {
        color: $darkTextColor;
        font-size: 20px;
        font-weight: 700;
        line-height: 30px;
        text-align: left;
        margin-bottom: 24px;
      }

      .block-description {
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        text-align: left;
        margin-bottom: 24px;

        .option-item {
          margin-bottom: 12px;

          span {
            color: $brandColor;
          }
          &:last-child{
            margin-bottom: 0;
          }
        }
      }

      .block-link {
        display: inline-block;
        margin-bottom: 24px;
        border-bottom: 2px solid $brandColor;
        padding-bottom: 4px;
        cursor: pointer;
        transition: all 0.2s;

        svg {
          margin-right: 4px;

          path {
            stroke: $brandColor;
            transition: all 0.2s;
          }
        }

        span {
          display: flex;
          align-items: center;
          color: $brandColor;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          word-wrap: break-word;
          transition: all 0.2s;
        }

        &:hover {
          border-color: black;

          span {
            color: black;
          }

          svg {
            path {
              stroke: black;
            }
          }
        }
      }
    }

    .blocks-second-right {
      width: 352px;
      padding: 0 52px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .block-inner {
        width: 100%;
      }

      .block-title {
        color: $darkTextColor;
        font-size: 20px;
        font-weight: 700;
        line-height: 30px;
        text-align: left;
        margin-bottom: 24px;
      }

      .block-link {
        width: 100%;
        display: flex;
        gap: 12px;
        color: $textColor;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        margin-bottom: 16px;
        cursor: pointer;

        svg {
          width: 24px;
          height: 24px;

          path {
            stroke: $brandColor;
          }
        }

        span {
          width: calc(100% - 24px);
        }
      }
    }

  }

  .blocks-third {
    padding: 120px 0;

    .block-title {
      color: $darkTextColor;
      font-size: 36px;
      font-weight: 700;
      line-height: 48px;
      text-align: center;
      margin-bottom: 20px;
    }

    .block-description {
      width: 60%;
      color: $textColor;
      font-size: 20px;
      font-weight: 500;
      line-height: 30px;
      text-align: center;
      margin: auto;
      margin-bottom: 64px;
    }

    .options-list {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      align-items: center;
      max-height: 850px;

      .option-item {
        width: 48%;
        height: max-content;
        box-sizing: border-box;
        border: 1px solid rgb(186, 187, 208);
        border-radius: 12px;
        display: flex;
        justify-content: space-between;
        padding: 16px 20px;
        margin-bottom: 20px;
        transition: all 0.2s;
        cursor: pointer;

        &-icon {
          width: 24px;
        }

        &-title {
          width: calc(100% - 24px);
          color: $textColor;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          text-align: left;
          padding-left: 12px;
        }

        &:hover {
          background-color: $brandColor;

          .option-item-icon {
            path {
              fill: white;
            }
          }

          .option-item-title {
            color: white;
          }
        }
      }
    }
  }

  @media screen and (max-width: 920px) {
    padding: 64px 0 0;
    overflow: hidden;

    &-bg {
      width: 100%;
      height: calc(360px + $headerHeight);
    }

    .info-sub-title {
      width: 100%;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 20px;
    }

    .info-title {
      width: 100%;
      color: rgb(21, 19, 25);
      font-size: 36px;
      font-weight: 700;
      line-height: 46px;
      margin-bottom: 24px;
    }

    .info-description {
      width: 100%;
      font-size: 20px;
      line-height: 30px;
      margin-top: 20px;
    }

    .blocks-first {
      padding: 90px $mainPadding;

      .options-list {

        .option-item {
          width: calc(100% / 2 - 16px);
        }
      }
    }

    .blocks-second {
      padding: 44px 0;
      background-color: $greyBgColor;
      flex-direction: column;

      .blocks-second-left,
      .blocks-second-right {
        box-sizing: border-box;
        border: 1px solid rgb(218, 218, 218);
        background: linear-gradient(139.01deg, rgb(238, 238, 238) -12.367%, rgba(254, 254, 254, 0.24) 95.461%, rgba(255, 255, 255, 0) 103.884%);
      }

      .blocks-second-left {
        width: calc(100% - ($mainPadding * 2));
        padding: 32px 24px;
        margin: 0 $mainPadding;

        .block-description {
          font-size: 16px;
          line-height: 24px;
        }
      }

      .blocks-second-right {
        width: calc(100% - ($mainPadding * 2));
        padding: 120px 52px;
        margin: 0 $mainPadding;
      }
    }

    .blocks-third {
      padding: 40px $mainPadding;

      .block-title {
        line-height: 38px;
        font-size: 26px;
      }

      .block-description {
        width: 90%;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 34px;
      }

      .options-list {
        max-height: unset;

        .option-item {
          width: 100%;
        }
      }
    }
  }

  @media screen and (max-width: 600px) {

    .blocks-first {

      .options-list {

        .option-item {
          width: 100%;
        }
      }
    }
  }
}

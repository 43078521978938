@import "../../assets/styles/fonts";
@import "../../assets/styles/mainVariables";

.footer-wrapper {
  -webkit-user-select:none!important;
  user-select: none!important;
  background-color: $greyBgColor;

  .top-part {
    display: flex;
    padding: 60px $mainPadding;
    .col{
      width: 40%;
      position: relative;
      .presentation-button{
        cursor: pointer;
        text-decoration: underline;
        position: absolute;
        bottom: 20px;
      }
    }
    .right-block{
      width: 60%;
      display: flex;
      //align-items: baseline;
      .col{
        width: 40%;
      }
    }
  }

  .bottom-part {
    margin: 0 $mainPadding;
    padding: 24px 0 44px 0;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid rgb(217, 217, 217);

    .copyright {
      color: $greyTextColor;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
    }

    .developed-by {
      display: flex;
      align-items: center;
      flex-direction: column;
      .develop{
        color: #667085;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0;
        text-align: right;
        display: flex;
        align-items: center;
        .footer-logo {
          margin-bottom: 0;
          display: flex;
          align-items: center;
          margin-left: 10px;
          height: 40px;
        }
      }
      .design{
        color: #667085;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0;
        text-align: right;
        display: flex;
        align-items: center;
        a{
          font-size: 13px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0;
          text-align: right;
          color: #667085;
          text-decoration: underline!important;
          margin-left: 6px;
        }
      }
    }
  }

  .col {
    color: #667085;

    .logo-wrapper {
      margin-bottom: 54px;
    }


    .title {
      color: rgb(186, 187, 208);
      font-size: 14px;
      font-weight: 700;
      line-height: 24px;
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 16px;
    }

    a {
      display: flex;
      align-items: center;
      color: rgb(68, 60, 82);
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
      margin-bottom: 12px;

      .item-description{
        white-space: break-spaces;
      }

      &:hover, p:hover {
        color: $brandColor;
      }
    }


    p, a {
      color: #696773;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 20px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .text-wrapper{
    width: 350px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;
    text-align: left;
  }

  .icons-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 32px;
    a {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0;
      text-align: left;
      margin-right: 16px;
      .social-icon {
        width: 34px;
        height: 34px;
        margin-right: 10px;
      }
      &:hover{
        color: black;
        text-decoration: underline;
      }
    }
  }

  .contact-item{
    display: flex;
    align-items: center;
    color: $textColor;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;

    svg{
      margin-right: 8px;
    }
  }

  @media screen and (max-width:700px){
    .top-part{
      flex-direction: column;
      justify-content: flex-start;
      .col{
        width: 100%;
        .presentation-button{
          position: unset;
        }
      }
      .right-block{
        width: 100%;
        flex-direction: column;
        .col{
          width: 96%;
        }
      }
      .col{
        margin-top: 20px;
        .text-wrapper{
          width: 100%;
        }
      }
    }

    .bottom-part{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .copyright{
        text-align: center;
      }
    }
  }

}

@font-face {
  font-family: Gilroy;
  src: url(../fonts/Gilroy-Regular.ttf) format('truetype');
  font-weight: 300 400;
}

@font-face {
  font-family: NotoArmenian;
  src: url(../fonts/NotoSansArmenian-Regular.ttf) format('truetype');
  font-weight: 300 400;
}

@font-face {
  font-family: NotoArmenian;
  src: url(../fonts/NotoSansArmenian-Medium.ttf) format('truetype');
  font-weight: 500 600;
}
@font-face {
  font-family: Gilroy;
  src: url(../fonts/Gilroy-Medium.ttf) format('truetype');
  font-weight: 500 600;
}

@font-face {
  font-family: NotoArmenian;
  src: url(../fonts/NotoSansArmenian-Bold.ttf) format('truetype');
  font-weight: 700 900;
}
@font-face {
  font-family:Gilroy;
  src: url(../fonts/Gilroy-Bold.ttf) format('truetype');
  font-weight: 700 900;
}
@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/fonts";

.homeBlock-wrapper {
  //padding: 0 $mainPadding;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FDFDFF;
  height: 100vh;
  max-height: 734px;

  .home-left-block {
    height: 100%;
    width: 40%;
    background-color: $greyBgColor;

    .home-imag {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      //position: relative;
      //padding-top: 142.5%;
      img {
        //position: absolute;
        width: 260px;
        //height: 100%;
        //top: 0;
        //left: 0;
        object-fit: cover;
      }
    }
  }

  .home-right-block {
    width: 60%;
    height: 100%;
    padding-left: 10%;
    padding-right: $mainPadding;
    background-color: #f8f8f8;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .homeBlock-sub-title {
      color: $brandColor;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      margin-bottom: 16px;
    }

    .homeBlock-title {
      color: $darkTextColor;
      font-size: 36px;
      font-weight: 700;
      line-height: 48px;
      text-align: left;
      margin-bottom: 16px;
    }

    .homeBlock-description {
      color: $textColor;
      font-size: 20px;
      font-weight: 500;
      line-height: 30px;
      max-height: 390px;
      overflow: hidden;
    }

    .homeBlock-more {
      cursor: pointer;
      margin-top: 20px;
      width: 198px;
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $textColor;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      background-color: transparent;
      border: 1px solid $textColor;
      border-radius: 60px;
      transition: all 0.2s;

      &:hover {
        background-color: $textColor;
        color: white;
      }
    }
  }

  &.reverse {
    flex-direction: row-reverse;
    justify-content: space-between;

    .home-left-block {
      width: 44%;
      background-color: transparent;
      background-image: url("../../../assets/images/block_bg.png");

      .home-imag {
        width: calc(100% - $mainPadding);
        height: unset;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        padding-top: 56%;
        top: 50%;
        transform: translate(-54px, -50%);

        img {
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: cover;
          top: 0;
          left: 0;
        }
      }
    }

    .home-right-block {
      width: calc(56% - 54px);
      background-color: transparent;
      padding-left: $mainPadding;
      padding-right: 32px;

      .homeBlock-description {
        //max-height: 270px;
      }

      .homeBlock-more{
        display: none;
      }
    }
  }

  @media screen and (max-width: 1050px) {
    padding: 0;
    height: unset;
    max-height: unset;
    background-image: url("../../../assets/images/block_bg.png");
    .home-right-block {
      width: 100%;
      padding: 60px $mainPadding;

      .homeBlock-title {
        font-size: 26px;
      }

      .homeBlock-description {
        font-size: 16px;
        max-height: unset;
      }

      .homeBlock-more {
        width: 100%;
      }
    }

    .home-left-block {
      display: none;
    }

    &.reverse {
      flex-direction: column-reverse;
      padding: 64px $mainPadding;
      max-height: unset;

      .home-right-block {
        width: 100%;
        padding: 0 0 32px 0;

        .homeBlock-title {
          font-size: 20px;
          font-weight: 700;
          line-height: 30px;
          margin-bottom: 16px;
        }
      }

      .home-left-block {
        width: 100%;
        display: block;
        background-image: unset;

        .home-imag {
          width: 100%;
          transform: unset;
        }
      }
    }
  }
}

@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/fonts";

.mainBlock-wrapper {
  width: 100%;
  height: 100vh;
  min-height: 400px;
  overflow: hidden;
  position: relative;
  display: flex;

  .left-part {
    width: 50%;
    height: 100%;
    background-color: $brandColor;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &-content {
      width: 100%;
      object-fit: cover;
      padding: 0 20px 0 $mainPadding;
      position: relative;

      .mainBlock-bottom-circle {
        position: absolute;
        left: $mainPadding;
        bottom: -30%;
      }
    }
  }

  .right-part {
    width: 50%;
    height: 100%;
    background-color: $greyBgColor;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;

    img {
      width: 467px;
      height: 542px;
      object-fit: contain;
      object-position: center;
      margin: auto;
      display: block;
      //margin-top: 50%;
      //transform: translateY(-50%);

    }

    &-ellipse-icon {
      position: absolute;
      width: 260px;
      height: 260px;
      left: 0;
      bottom: 0;
      transform: translate(-50%, -25%);
    }

    &-circle {
      position: absolute;
      width: 60px;
      height: 60px;
      right: $mainPadding;
      bottom: 0;
      transform: translate(50%, -50%);
      background: white;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      border: 2px solid black;

      svg {
        transform: rotate(90deg);
      }
    }

    &-arrow {
      position: absolute;
      width: 60px;
      height: 60px;
      right: calc($mainPadding + 66px);
      bottom: 0;
      transform: translate(0%, -50%);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &-ellipse-icon {
    display: none;
  }

  &-arrow {
    display: none;
  }

  &-circle {
    display: none;
  }

  .mainBlock-title {
    width: 100%;
    max-height: 144px;
    overflow: hidden;
    color: #151319;
    font-size: 60px;
    font-weight: 700;
    line-height: 72px;
    margin-bottom: 20px;
  }

  .mainBlock-sub-title {
    width: 100%;
    height: 24px;
    color: $textColor;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 20px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .mainBlock-description {
    width: 100%;
    color: $darkTextColor;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    margin-bottom: 40px;
  }

  .mainBlock-btn {
    width: fit-content;
    color: white;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    display: flex;
    align-items: center;
    padding: 16px 40px;
    background-color: $darkTextColor;
    border-radius: 60px;
    border: 1px solid transparent;
    transition: all 0.2s;

    svg {
      margin-left: 2px;
    }

    &:hover {
      background-color: transparent;
      color: $darkTextColor;
      border: 1px solid $darkTextColor;

      svg {
        path {
          stroke: $darkTextColor;
        }
      }
    }
  }

  @media screen and (max-width: 1050px) {
    height: unset;
    min-height: unset;
    padding-top: $headerHeight;
    .left-part {
      width: 100%;
      height: 100%;
      background-color: $brandColor;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 75px 0;

      &-content {
        width: 100%;
        object-fit: cover;
        padding: 0 20px 0 $mainPadding;

        .mainBlock-bottom-circle {
          display: none;
        }
      }
    }

    .right-part {
      display: none;
    }

    &-ellipse-icon {
      display: block;
      position: absolute;
      width: 205px;
      height: 205px;
      right: 0;
      top: 0;
      transform: translate(25%, 15%);
    }

    &-circle {
      position: absolute;
      width: 40px;
      height: 40px;
      right: $mainPadding;
      top: calc(54px);
      transform: translateY(50%);
      background: white;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      border: 2px solid black;

      svg {
        width: 16px;
        height: 16px;
        transform: rotate(90deg);
      }
    }

    &-arrow {
      position: absolute;
      width: 40px;
      height: 40px;
      right: calc($mainPadding + 22px);
      bottom: 0;
      transform: translate(0%, -50%);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media screen and (max-width: 800px) {
    .mainBlock-title {
      max-height: 92px;
      font-size: 36px;
      line-height: 46px;
    }

    .mainBlock-sub-title {
      font-size: 14px;
      line-height: 20px;
    }

    .mainBlock-description {
      font-size: 18px;
      line-height: 28px;
    }

    .mainBlock-btn {
    }
  }

  @media screen and (max-width: 500px) {
    .mainBlock-btn {
      width: 100%;
      justify-content: center;
    }
  }
}

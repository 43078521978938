@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/fonts";

.contracts-wrapper {

  &-top {
    padding: 134px $mainPadding 0;
    position: relative;
    overflow: hidden;

    &-ellipse-icon {
      position: absolute;
      top: 134px;
      left: 0;
      height: calc(100% - 268px);
      transform: translateX(-50%);
    }
  }

  &-title {
    width: 80%;
    color: $darkTextColor;
    font-size: 36px;
    font-weight: 700;
    line-height: 48px;
    margin-bottom: 32px;
  }

  &-description {
    width: 80%;
    color: $textColor;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    margin-bottom: 40px;
  }

  &-text {
    width: 80%;
    color: $textColor;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    margin-bottom: 32px;
  }

  &-counts {
    display: flex;
    justify-content: space-between;
    margin-bottom: 118px;

    .counts-item {
      width: 22%;
      padding-left: 3%;
      border-right: 1px solid $borderColor;

      span {
        &:first-child {
          display: block;
          color: $brandColor;
          font-size: 40px;
          font-weight: 700;
          line-height: 52px;
          margin-bottom: 2px;
        }

        &:last-child {
          color: $textColor;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
        }
      }

      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        border-right: 0;
      }
    }
  }

  &-partners {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 80px;
    .partners-list-wrapper {
      position: relative;
      width: 100% !important;

      .slick-slider {
        width: 100% !important;

        .slick-list {
          width: 100% !important;
          overflow: hidden;

          .slick-track {
            width: 100% !important;
            padding-top: 5px;
            display: flex;
            @media screen and (max-width: 400px) {
              //margin-right: 90px;
            }
          }
        }
      }

    }

      .partner-item {
        border: 1px solid $borderColor;
        width: 160px;
        height: 160px;
        margin-right: 32px;

        .partner-content {
          width: 100%;
          height: 100%;
          padding-top: 100%;
          position: relative;

          img {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            object-fit: contain;
          }
        }
      }
  }

  @media all and (max-width: 1200px) {
    &-top {
      &-ellipse-icon {
       display: none;
      }
    }
  }

  @media all and (max-width: 800px) {
    background-color: #f6f6f6;

    &-top {
      padding: 82px $mainPadding 0;
    }

    &-title {
      width: 100%;
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 26px;
    }

    &-description {
      width: 100%;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 44px;
    }

    &-text {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 34px;
    }

    &-counts {
      flex-wrap: wrap;
      margin-bottom: 72px;

      .counts-item {
        width: 50%;
        padding-left: 0;
        border-right: 0;
        text-align: center;
        margin-bottom: 24px;

        span {
          &:first-child {
            display: block;
            color: $brandColor;
            font-size: 40px;
            font-weight: 700;
            line-height: 52px;
            margin-bottom: 2px;
          }

          &:last-child {
            color: $textColor;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
          }
        }

        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          border-right: 0;
        }
      }
    }

    &-partners {
      padding-right: 0;
      top: unset !important;
      width: 100% !important;
      border: unset !important;

      .partners-list-wrapper {
        width: 100% !important;
      }

      .next-btn, .prev-btn {
        display: none;
      }
    }
  }

  @media all and (max-width: 700px) {
    &-partners {

      .partners-list-wrapper {
        .partner-item{
          width: 120px;
          height: 52px;
          margin-right: 12px;
          border: 0;
          .partner-content{
            padding-top: 0;
          }
        }
      }
    }
  }
}
